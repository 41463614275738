// src/pages/Concepts.jsx
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import { concepts } from '../data/concepts';

const ImageSlideshow = ({ images }) => {
  const wrap = (min, max, v) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
  };

  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);
  
  useEffect(() => {
    images.forEach(image => {
      const img = new Image();
      img.src = image.url;
    });
  }, [images]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className="relative group overflow-hidden bg-gray-100 rounded-lg">
      <div className="aspect-[1280/832] relative">
        <AnimatePresence initial={false} mode="wait" custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            initial={{ 
              opacity: 0,
              x: direction > 0 ? 100 : -100
            }}
            animate={{ 
              opacity: 1,
              x: 0,
              transition: {
                x: { type: "spring", stiffness: 500, damping: 30 },
                opacity: { duration: 0.1 }
              }
            }}
            exit={{ 
              opacity: 0,
              x: direction < 0 ? 100 : -100,
              transition: {
                x: { type: "spring", stiffness: 500, damping: 30 },
                opacity: { duration: 0.1 }
              }
            }}
            className="absolute inset-0 w-full h-full"
          >
            <img
              src={images[imageIndex].url}
              alt={images[imageIndex].alt}
              className="w-full h-full object-contain"
              draggable="false"
            />
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={() => paginate(-1)}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/75"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        onClick={() => paginate(1)}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/75"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>

      {/* Progress Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              const newDirection = index - imageIndex;
              setPage([index, newDirection]);
            }}
            className={`h-1.5 rounded-full transition-all duration-300 ${
              index === imageIndex ? 'w-6 bg-white' : 'w-1.5 bg-white/50'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

const ThumbnailSlideshow = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <div className="relative overflow-hidden h-full group">
      <AnimatePresence initial={false} mode="wait">
        <motion.img
          key={currentIndex}
          src={images[currentIndex].url}
          alt={images[currentIndex].alt}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full h-full object-cover"
        />
      </AnimatePresence>

      {/* Progress Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={(e) => {
              e.preventDefault(); // Prevent navigation when clicking indicators
              setCurrentIndex(index);
            }}
            className={`h-1.5 rounded-full transition-all duration-300 
              ${index === currentIndex ? 'w-6 bg-white' : 'w-1.5 bg-white/50'}
              opacity-0 group-hover:opacity-100
            `}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      {/* Optional: Pause animation on hover */}
      <div 
        className="absolute inset-0 bg-black/0 group-hover:bg-black/10 transition-colors duration-300"
        onClick={(e) => e.preventDefault()} // Prevent navigation when clicking the overlay
      />
    </div>
  );
};

const MediaContent = ({ content }) => {
  switch (content.type) {
    case 'image':
      return (
        <div className="aspect-[1280/832] rounded-lg overflow-hidden bg-gray-100">
          <img
            src={content.url}
            alt={content.alt}
            className="w-full h-full object-contain"
          />
        </div>
      );
    
    case 'slideshow':
      return <ImageSlideshow images={content.images} />;
    
      case 'video':
        return (
          <div className="aspect-[1280/832] rounded-lg overflow-hidden bg-gray-100">
            <video
              autoPlay          // Add this
              loop              // Add this
              muted             // Add this for autoplay to work
              playsInline      // Add this for better mobile support
              className="w-full h-full object-contain"
              poster={content.thumbnail}
            >
              <source src={content.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
    
    case 'gif':
      return (
        <div className="aspect-[1280/832] rounded-lg overflow-hidden bg-gray-100">
          <img
            src={content.url}
            alt={content.alt}
            className="w-full h-full object-contain"
          />
        </div>
      );
    
    default:
      return null;
  }
};
const ThumbnailVideo = ({ videoUrl }) => {
  return (
    <div className="relative overflow-hidden h-full">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

const ConceptsGrid = () => {
  return (
    <div className="space-y-32">
      {Object.entries(concepts).map(([id, concept]) => (
        <Link to={`/concepts/${id}`} key={id} className="block">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 mb-16 pb-8 border border-ng-100"
          >
            {/* Thumbnail */}
            <div className="aspect-[1280/832] overflow-hidden bg-gray-100">
              {concept.media.type === 'slideshow' ? (
                <ThumbnailSlideshow images={concept.media.images} />
              ) : concept.media.type === 'video' ? (
                <ThumbnailVideo videoUrl={concept.media.url} />
              ) : (
                <img
                  src={concept.media.url}
                  alt={typeof concept.title === 'function' ? 'Concept thumbnail' : concept.title}
                  className="w-full h-full object-cover"
                />
              )}
            </div>
            
            {/* Content */}
            <div className="p-8">
              <h3 className="text-2xl font-semibold text-gray-900 mb-3">
                {typeof concept.title === 'function' ? concept.title() : concept.title}
              </h3>
              
              <p className="text-gray-600 text-lg mb-4">
                {concept.subtitle}
              </p>
            </div>
          </motion.div>
        </Link>
      ))}
    </div>
  );
};

const ConceptPage = () => {
  const { id } = useParams();
  
  if (!id) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
      >
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Concepts & Explorations
          </h1>
          <p className="text-lg text-gray-600">
            A collection of design explorations and concept work for various projects.
          </p>
        </div>

        <ConceptsGrid />
      </motion.div>
    );
  }

  const data = concepts[id];

  if (!data) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="text-2xl font-bold text-gray-900">Concept not found</h1>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
    >
      {/* Back Button */}
      <div className="max-w-3xl mb-8">
        <Link 
          to="/concepts"
          className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors"
        >
          <svg 
            className="w-5 h-5 mr-2" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M15 19l-7-7 7-7" 
            />
          </svg>
          Back to concepts
        </Link>
      </div>

      {/* Header */}
      <div className="max-w-3xl mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          {typeof data.title === 'function' ? data.title() : data.title}
        </h1>
        <p className="text-lg text-gray-600">
          {data.subtitle}
        </p>
      </div>

      {/* Media Content */}
      <div className="mb-8 bg-white rounded-lg shadow-sm overflow-hidden">
        <MediaContent content={data.media} />
      </div>

      {/* Caption */}
      <div className="max-w-3xl mx-auto text-center">
        <p className="text-gray-600 italic">
          {data.caption}
        </p>
      </div>
    </motion.div>
  );
};

export default ConceptPage;