import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import WorkCard from '../work/WorkCard';
import { workData } from '../../data/work';

export default function WorkPreview() {
  const previewWork = workData.slice(0, 2);

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  return (
    <motion.section 
      initial="initial"
      animate="animate"
      className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
    >
      <motion.div 
        variants={fadeInUp}
        className="flex justify-between items-center mb-8"
      >
        <h2 className="text-xl font-semibold text-black">
          Selected work
        </h2>
        <Link 
          to="/work"
          className="inline-flex items-center gap-2 group font-normal text-gray-800 hover:text-gray-700 transition-colors"
        >
          <span>View all work</span>
          <svg
            className="w-4 h-4 transition-transform group-hover:translate-x-0.5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </Link>
      </motion.div>

      <motion.div 
        variants={fadeInUp}
        className="grid grid-cols-1 md:grid-cols-2 gap-8"
      >
        {previewWork.map((work, index) => (
          <motion.div
            key={work.id || index}
            variants={fadeInUp}
            transition={{ delay: index * 0.2 }}
          >
            <WorkCard {...work} />
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
}