// src/pages/Home.jsx
import React from 'react';
import Hero from '../components/home/Hero';
import ProjectsPreview from '../components/home/ProjectsPreview';
import WorkPreview from '../components/home/WorkPreview';
import WritingsPreview from '../components/writings/WritingsPreview';
import Footer from '../components/layout/Footer';
import InfiniteCarousel from '../components/common/InfiniteCarousel';

export default function Home() {
  return (
    <>
      <Hero />
      <ProjectsPreview />
      <WorkPreview />
      {/* <InfiniteCarousel /> */}
      {/* <WritingsPreview /> */}
      {/* <Footer /> */}
    </>
  );
}