// // Then in src/pages/Projects.jsx
// import { motion } from 'framer-motion';
// import ProjectCard from '../components/projects/ProjectCard';
// import PageTransition from '../components/common/PageTransition';
// import { projects } from '../data/projects';

// export default function Projects() {
//   return (
//     <PageTransition>
//       <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
//         {/* Header Section */}
//         <div className="max-w-3xl mb-16">
//           <h1 className="text-4xl font-bold text-gray-900 mb-6">
//             All projects
//           </h1>
//           <p className="text-lg text-gray-600">
//             A collection of side projects and products I've built. These projects represent 
//             my journey in exploring new technologies and solving interesting problems.
//           </p>
//         </div>

//         {/* Projects Grid */}
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           {projects.map((project, index) => (
//             <ProjectCard
//               key={index}
//               title={project.title}
//               description={project.description}
//               image={project.image}
//               siteUrl={project.siteUrl}
//               onClick={() => {
//                 console.log('Project clicked:', project.title);
//               }}
//             />
//           ))}
//         </div>
//       </div>
//     </PageTransition>
//   );
// }

import { motion } from 'framer-motion';
import ProjectCard from '../components/projects/ProjectCard';
import PageTransition from '../components/common/PageTransition';
import { projects } from '../data/projects';

export default function Projects() {
  return (
    <PageTransition>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        {/* Header Section */}
        <div className="max-w-3xl mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            All projects
          </h1>
          <p className="text-lg text-gray-600">
            A collection of side projects and products I've built. These projects represent 
            my journey in exploring new technologies and solving interesting problems.
          </p>
        </div>

        {/* Projects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              {...project} // This spreads all properties from the project object
              onClick={() => {
                console.log('Project clicked:', project.title);
              }}
            />
          ))}
        </div>
      </div>
    </PageTransition>
  );
}