// src/App.jsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Projects from './pages/Projects';
import Home from './pages/Home';
import Work from './pages/Work';
import WritingsPage from './pages/writings';
import WritingDetail from './pages/writings/WritingDetail';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Poetry from './pages/Poetry';
import Now from './pages/Now';
import ConceptPage from './pages/Concepts';

export default function App() {
  return (
    <BrowserRouter>
      <div className="fixed-wrapper min-h-screen">
        {/* Grid Background */}
        {/* <div
          className="fixed inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgb(0 0 0 / 0.03) 1px, transparent 1px),
              linear-gradient(to bottom, rgb(0 0 0 / 0.03) 1px, transparent 1px)
            `,
            backgroundSize: '20px 20px',
            backgroundColor: 'white',
            pointerEvents: 'none'
          }}
        /> */}

        {/* Gradient Overlay */}
        <div
          className="fixed inset-x-0 top-0 h-32 pointer-events-none"
          style={{
            background: 'linear-gradient(to bottom, white 15%, rgba(255, 255, 255, 0.5) 45%, transparent)'
          }}
        />

        {/* Content */}
        <div className="content-container relative flex flex-col min-h-screen bg-white">
          <Header />

          <main className="flex-grow pt-24"> {/* Added pt-24 here */}
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8"> {/* Added container */}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/work" element={<Work />} />
                {/* <Route path="/poetry" element={<Poetry />} /> */}
                {/* <Route path="/now" element={<Now />} /> */}
                <Route path="/concepts" element={<ConceptPage />} />
                <Route path="/concepts/:id" element={<ConceptPage />} />
                {/* <Route path="/writings" element={<WritingsPage />} />
                <Route path="/writings/:slug" element={<WritingDetail />} /> */}
              </Routes>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}