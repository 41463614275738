import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { Menu, X } from 'lucide-react';

const FloatingNav = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: "Side projects", path: "/projects" },
    { name: "Work", path: "/work" },
    { name: "Concepts", path: "/concepts" },
  ];

  return (
    <div className="fixed inset-x-0 top-0 z-50">
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`
          relative flex w-full items-center justify-between
          border-b border-gray-200 bg-white 
          px-6 py-5
          ${isScrolled ? 'border-gray-200' : 'border-gray-200'}
        `}
      >
        {/* Content Container */}
        <div className="relative flex w-full items-center justify-between max-w-7xl mx-auto">
          {/* Logo/Brand */}
          <Link 
            to="/" 
            className="text-xl font-medium text-gray-900 hover:text-gray-900 transition-colors"
          >
            Shubham Bhatt
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden sm:flex items-center space-x-1">
            {navItems.map((item, index) => (
              <motion.div
                key={item.path}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative"
              >
                <Link
                  to={item.path}
                  className="text-base font-normal text-gray-600 px-4 py-2 rounded-xl transition-all hover:text-gray-900 hover:bg-gray-100 block"
                >
                  {item.name}
                </Link>
              </motion.div>
            ))}
          </nav>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="sm:hidden relative flex items-center justify-center w-6 h-6 text-gray-900"
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>
      </motion.div>

      {/* Mobile Menu Dropdown */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{
          opacity: isMobileMenuOpen ? 1 : 0,
          y: isMobileMenuOpen ? 0 : -20,
          display: isMobileMenuOpen ? 'block' : 'none',
        }}
        transition={{ duration: 0.2 }}
        className="absolute w-full sm:hidden"
      >
        <div className="bg-white border-b border-gray-200 p-4">
          {navItems.map((item, index) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={() => setIsMobileMenuOpen(false)}
              className="flex items-center w-full px-4 py-3 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-xl transition-colors"
            >
              {item.name}
            </Link>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default FloatingNav;