import PageTransition from '../components/common/PageTransition';
import WorkCard from '../components/work/WorkCard';
import { workData } from '../data/work';

export default function Work() {
  return (
    <PageTransition>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        {/* Header Section */}
        <div className="max-w-3xl mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            Work
          </h1>
          <p className="text-lg text-gray-600">
            Selected projects from my professional career, showcasing my
            experience in product design and development.
          </p>
        </div>

        {/* Work Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {workData.map((work, index) => (
            <WorkCard
              key={index}
              {...work}
              onClick={() => {
                console.log('Work clicked:', work.title);
              }}
            />
          ))}
        </div>
      </div>
    </PageTransition>
  );
}