import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

export default function WorkCard({ 
  title,
  company,
  year,
  thumbnail,
  description,
  caseStudyLink,
  tags = [],
  tools = [],
  onClick
}) {
  const handleCaseStudyClick = (e) => {
    e.stopPropagation();
    if (caseStudyLink) {
      window.open(caseStudyLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <motion.div
      className="group relative bg-white rounded-2xl overflow-hidden shadow-custom hover:shadow-custom-hover transition-all duration-300 border border-ng-100"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Project Thumbnail */}
      <div className="relative aspect-[16/9] overflow-hidden bg-gray-100">
        <img
          src={thumbnail}
          alt={`${title} - ${company} project thumbnail`}
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
          onError={(e) => {
            e.target.src = '/placeholder.png';
            console.log(`Failed to load thumbnail for ${title}`);
          }}
        />
      </div>

      {/* Content */}
      <div className="p-6 space-y-3">
        {/* Company & Year */}
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-600">{company}</span>
          <span className="text-sm text-gray-500">{year}</span>
        </div>

        {/* Title */}
        <h3 className="text-lg font-semibold text-gray-900 group-hover:text-gray-700 transition-colors">
          {title}
        </h3>
        
        {/* Description */}
        <p className="text-gray-600 line-clamp-2 text-md">
          {description}
        </p>

        {/* Action Link */}
        <div className="flex items-center justify-between pt-4">
          <button
            onClick={handleCaseStudyClick}
            className="text-gray-900 font-medium hover:text-gray-600 flex items-center transition-colors"
          >
            <span className="relative z-10 pr-1">Visit site </span>
            <svg
            className="w-4 h-4 transition-transform group-hover:translate-x-0.5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
          </button>
        </div>
      </div>

      {/* Hover Overlay */}
      <div className="absolute inset-0 bg-black/[0.01] opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none" />
    </motion.div>
  );
}

// Default props
WorkCard.defaultProps = {
  tags: [],
  tools: [],
  onClick: () => {},
};