// src/data/concepts.js
import Dashboard from '../assets/images/Dashboard.png';
import Clients from '../assets/images/Clients.png';
import Leads from '../assets/images/Leads.png';
import Therapists from '../assets/images/Therapists.png';
import Sidebars from '../assets/images/Sidebars.png';
import ButtonVideo from '../assets/videos/Button2.mp4';
import editorSnippet from '../assets/videos/EditorSnippet.mp4';

export const concepts = {
  button: {
    title: () => (
      <span>
        Learning by copying - Practicing a button concept shared by{' '}
        <a 
          href="https://x.com/alvishbaldha/status/1848250802391126509"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Alvish
        </a>
      </span>
    ),
    subtitle: "Buttons can be fun, sometimes.",
    media: {
      type: 'video',
      url: ButtonVideo,
      alt: "Buttons, buttons, buttons"
    },
    caption: "Creating this button was fun"
  }, 
  editorSnippet: {
    title: "Editor snippet, dark and light mode",
    subtitle: "Rich text editor, both dar and light mode",
    media: {
      type: 'video',
      url: editorSnippet,
      alt: "Rich text editor"
    },
    caption: "Editor, both modes"
  },
  therapy: {
    title: "Exploration for a therapy ops platform",
    subtitle: "Exploring different approaches to make navigation more intuitive and engaging",
    media: {
      type: 'slideshow',
      images: [
        { url: Dashboard, alt: "Dashboard - Overview of key metrics and insights" },
        { url: Clients, alt: "Clients Interface - Managing client relationships" },
        { url: Leads, alt: "Leads Management - Track and convert potential clients" },
        { url: Therapists, alt: "Therapists View - Therapist management dashboard" }
      ],
    },
    caption: "Early exploration of navigation patterns focusing on user engagement and clarity"
  },
  sidebar: {
    title: "Exploring sidebar navigation patterns",
    subtitle: "A study on effective sidebar layouts and interactions for complex applications",
    media: {
      type: 'image',
      url: Sidebars,
      alt: "Sidebar navigation exploration"
    },
    caption: "Design exploration for an intuitive and scalable sidebar navigation system"
  }
  
};